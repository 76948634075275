header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;   
}

.header-name {
    font-size: 5rem;
    font-style: italic;
    font-weight: 700;
}




.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}



.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}



.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 20rem;
    height: 17rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 1.5rem;
}

.me::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), var(--color-bg));
    z-index: 1;
}




.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

@media screen and (max-width: 600) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}