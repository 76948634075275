.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio-header {
    font-size: 4rem;
    font-weight: 700;
}

.portfolio__item {
    background: var(--color-light);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 0.25rem solid var(--color-bg-variant);
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    display: flex;
    justify-content: center;
    margin: 1.2re, 0 2rem;
    font-size: 1.5rem;
    color: var(--color-bg-variant);
}

.portfolio__item:hover h3 {
    color: var(--color-light);
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}




@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}