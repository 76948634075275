.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
}

.popup-inner {
    position: relative;
    padding: 2rem;
    width: 100%;
    max-width: 40rem;
    background-color: #FFF;
    color: var(--color-bg-variant);
    border-radius: 1rem;
}

.popup-inner .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}